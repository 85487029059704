import { db } from "../firebase";

function sendToServer(qData, values, client, id, subscribed, completeDoc) {
  const formattedData = qData.map(q => ({
    question: q.question,
    answer: q.answer.dk_check ? "DK: " + q.answer.dontknow : q.answer.normal
  }));

  var results = {};
  var returnUrl = "";
  results.qData = qData;
  results.url = window.location.href;
  results.subscribed = subscribed;
  results.company = values.company;
  results.name = values.name;
  results.email = values.email;
  results.timestamp = new Date();
  results.data = formattedData;
  results.id = id;
  results.client = client;
  returnUrl = window.location.href + "?" + completeDoc;

  if (client === undefined) {
    results.id = "";
  }
  if (id === undefined) {
    results.client = "";
  }

  db.collection("Data")
    .doc(completeDoc)
    .set({
      results,
      returnUrl
    })
    .then(function() {
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
}

export default sendToServer;
