import { db } from "../firebase";

function checkAlreadyComplete(
  completeDB,
  setCompleteDB,
  setDBResults
) {

  var query = window.location.search.substring(1);
  var vars = query.split("?");
  var clientUrl = vars[vars.length - 1];

  if (completeDB && clientUrl.length > 10) {
    var docRef = db.collection("Data").doc(clientUrl);
    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          setDBResults(doc.data().results.qData);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function(error) {
        console.log("Error getting document:", error);
      });
    setCompleteDB(false);
  }
}

export default checkAlreadyComplete;
