import React from "react";
import BigLogo from "../images/BigLogo.png";
import Proptypes from "prop-types";
import Logo from "../images/Logo.png";

export default function Preamble(props) {
  function renderPreambleShort() {
    return (
      <div className="white-box-wrapper-short">
        <div className="text">
          <div
            style={{
              textTransform: "uppercase",
              fontSize: "3vh",
              margin: "2.5vh",
              fontFamily: "BrandonGrotesque"
            }}
          >
            Tech Leader Self-Assessment
            {window.innerWidth > 1400 && (
              <img className="Logo" src={Logo} alt="Graphic" />
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderPreamble() {
    return (
      <div className="preamble-white-box">
        <img className="SevenCTOs" src={BigLogo} alt="Graphic" />

        <div className="text1">
          <div className="Title">
            {window.innerWidth > 1000 && (
              <h1>
                <b>Tech Leader Self-Assessment Tool</b>
              </h1>
            )}
            {window.innerWidth < 1000 && (
              <h2>
                <b>Tech Leader Self-Assessment Tool</b>
              </h2>
            )}
          </div>

          <div className="points">
            <ul
              style={{
                fontSize: "3vh",
                fontWeight: "500",
                textAlign: "left",
                fontFamily: "BrandonGrotesque"
              }}
            >
              <li>
                This questionnaire is designed to assess your organization’s
                technical and operational performance.{" "}
              </li>

              <li>
                Your answers are evaluated and compared against our internal
                database of results.
              </li>

              <li>
                Your answers are benchmarked against your peers, indicating
                relative performance.
              </li>

              <li>
                The more honest your answers, the more useful the results.
              </li>

              <li>Target time-to-complete is less than 10 mins.</li>
            </ul>
            {/*       {window.innerWidth > 1000 && !props.is_7ctos && (
        <img className="BigLogo" src={BigLogo} alt="Graphic" />
      )} */}
          </div>
        </div>
      </div>
    );
  }

  if (!props.extendedVersion) {
    return renderPreambleShort();
  } else {
    return renderPreamble();
  }
}

Preamble.propTypes = {
  is_7ctos: Proptypes.bool.isRequired
};
