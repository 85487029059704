import React, { useState } from "react";
import Question from "./components/question";
import QuestionCategory from "./components/category";
import Preamble from "./components/preamble";
import getQueryDict from "./functions/queryString";
import "./App.css";
import Collab from "./images/Collab.png";
import Solo from "./images/Solo.png";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import produce from "immer";
import Particles from "react-particles-js";
import {
  dk_unit_1,
  dk_unit_2,
  dk_unit_3
} from "./components/unsureRadioButtons.jsx";
import { AssertionError } from "assert";
import VisibilitySensor from "react-visibility-sensor";
import { Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import checkAlreadyComplete from "./functions/checkCompletedServer";

import QuestionCategoryShort from "./components/Short_category";

function initializeReactGA() {
  ReactGA.initialize("UA-126956513-1");
  ReactGA.pageview("/React-Test-10");
}

function App() {
  const [extendedVersion, setExtendedVersion] = useState(false);

  const [isLoaded, setLoaded] = useState(false);
  const [qData, setqData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentCategory, setCurrentCategory] = useState("");
  const [userData, setUserData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscribed, setSubscribed] = useState(true);
  const [complete, setComplete] = useState(false);
  const [completeDoc, setCompleteDoc] = useState("");
  const [completeDB, setCompleteDB] = useState(true);
  const [DBResults, setDBResults] = useState([]);
  const [DBloaded, setDBloaded] = useState(false);
  const queryDict = getQueryDict();
  //Google analytics
  const [clickedAnything, setClickedAnything] = useState(false);

  checkAlreadyComplete(completeDB, setCompleteDB, setDBResults);
  function loadPre() {
    if (DBResults.length > 20) {
      if (!DBloaded) {
        setIsSubmitted(true);
        setqData(DBResults);
        setDBloaded(true);
      }
    }
  }
  loadPre();

  //-New

  const [urlLoad, setUrlLoad] = useState(false);

  var data = require("./data/QuestionsShort.json");

  function model() {
    if (window.location.href.includes("extended")) {
      setExtendedVersion(true);
      data = require("./data/Questions.json");
    }
    setUrlLoad(true);
  }

  if (!urlLoad) model();

  //

  const particleEffects = {
    particles: {
      number: {
        value: 60
      },
      size: {
        value: 3
      }
    },
    interactivity: {
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        }
      }
    }
  };

  function parseData(data) {
    if (!isLoaded) {
      for (let i = 0; i < data.length; i++) {
        data[i].answer = {};
        data[i].visible = i === 0 ? true : false;
        data[i].answer.normal = "";
        data[i].answer.dk_check = false;
        data[i].answer.dontknow = "";
        data[i].score = 0;
      }
      setqData(data);
      setLoaded(true);
      initializeReactGA();
      setCurrentCategory(data[currentIndex].category);
    }
  }

  parseData(data);

  function setUserAnswer(value, questionIndex) {
    setqData(
      produce(qd => {
        qd[questionIndex].answer.normal = value;
        for (let i = 0; i < qd[questionIndex].answers.length; i++) {
          if (qd[questionIndex].answers[i].ans === value)
            qd[questionIndex].score = qd[questionIndex].answers[i].score;
        }
        if (questionIndex < qd.length - 1) {
          qd[questionIndex + 1].visible = true;
        }
        return qd;
      })
    );
    scrollToNext("element" + (questionIndex + 1), questionIndex);
  }

  function setDkCheck(questionIndex) {
    setqData(
      produce(qd => {
        qd[questionIndex].answer.dk_check = !qd[questionIndex].answer.dk_check;
        qd[questionIndex].answer.normal = "";
        qd[questionIndex].answer.dontknow = "";
        return qd;
      })
    );
  }

  function setUnsureUserAnswer(value, questionIndex) {
    setqData(
      produce(qd => {
        qd[questionIndex].answer.dontknow = value;
        switch (qd[questionIndex].dk_unit) {
          case 1:
            qd[questionIndex].score = dk_unit_1[value];
            break;
          case 2:
            qd[questionIndex].score = dk_unit_2[value];
            break;
          case 3:
            qd[questionIndex].score = dk_unit_3[value];
            break;
          default:
            AssertionError("Should not happen");
        }
        if (questionIndex < qd.length - 1) {
          qd[questionIndex + 1].visible = true;
        }
        return qd;
      })
    );
    scrollToNext("element" + (questionIndex + 1), questionIndex);
  }

  function scrollToNext(element, questionIndex) {
    setCurrentIndex(questionIndex);
    scroller.scrollTo(element, {
      duration: 1000,
      delay: 0,
      smooth: true
    });
  }

  var is_7ctos = false;
  is_7ctos = queryDict;

  //GA

  function clickAnything() {
    if (!clickedAnything) {
      ReactGA.event({
        category: "User",
        action: "Clicked on first answer"
      });
      setClickedAnything(true);
    }
  }

  function GAemail() {
    ReactGA.event({
      category: "Conversion",
      action: "Submitted Email"
    });
  }

  function GAcomplete() {
    ReactGA.event({
      category: "User",
      action: "Completed SA & got to results page"
    });
  }

  function GASubscribed() {
    ReactGA.event({
      category: "Conversion",
      action: "HOT LEAD!"
    });
  }

  //GA

  if (isLoaded && !complete && !extendedVersion) {
    return (
      <Router>
        <Route exact path="">
          <div className="App">
            <QuestionCategoryShort
              currentCategory={currentCategory}
              currentIndex={currentIndex}
              setUserData={setUserData}
              userData={userData}
              qData={qData}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              category={
                currentIndex + 1 < qData.length
                  ? qData[currentIndex + 1].category
                  : ""
              }
              is_7ctos={is_7ctos}
              setSubscribed={setSubscribed}
              subscribed={subscribed}
              setCompleteDoc={setCompleteDoc}
              completeDoc={completeDoc}
              setComplete={setComplete}
              DBloaded={DBloaded}
              extendedVersion={extendedVersion}
              GAemail={GAemail}
              GAcomplete={GAcomplete}
              GASubscribed={GASubscribed}
            />
            <Preamble is_7ctos={is_7ctos} extendedVersion={extendedVersion}/>
            <Particles className="background-short" params={particleEffects} />
            <div className="CTO-self-assessment">
              {qData.map((question, index) => (
                <VisibilitySensor
                  key={"vis" + index}
                  onChange={isVis => {
                    if (isVis) {
                      setCurrentCategory(qData[index].category);
                    }
                  }}
                >
                  <Element className={"element" + index} key={index}>
                    {qData[index].visible &&
                    (index < 22 || qData[21].answer.normal === "Yes") ? (
                      <div className={"question-box"} key={index}>
                        <div
                          className={"Card-Disappear index" + index + " short"}
                          key={index}
                        >
                          <Question
                            questionIndex={index}
                            isLoaded={isLoaded}
                            state={question}
                            setUserAnswer={setUserAnswer}
                            setUnsureUserAnswer={setUnsureUserAnswer}
                            setDkCheck={setDkCheck}
                            clickAnything={clickAnything}
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </Element>
                </VisibilitySensor>
              ))}
            </div>

            {window.innerWidth < 1000 && window.innerWidth > 800 && is_7ctos && (
              <img
                className="Collab"
                src={Collab}
                alt="Graphic"
                style={{
                  width: "25vw",
                  position: "sticky",
                  left: "70vw",
                  bottom: "2vh"
                }}
              />
            )}

            {window.innerWidth < 800 && is_7ctos && (
              <img
                className="Collab"
                src={Collab}
                alt="Graphic"
                style={{
                  width: "33vw",
                  position: "sticky",
                  left: "63vw",
                  bottom: "2vh"
                }}
              />
            )}

            {window.innerWidth < 1000 && !is_7ctos && (
              <img
                className="Collab"
                src={Solo}
                alt="Graphic"
                style={{
                  width: "22vw",
                  position: "sticky",
                  left: "72vw",
                  bottom: "2vh"
                }}
              />
            )}
          </div>
        </Route>
      </Router>
    );
  } else if (isLoaded && complete && !extendedVersion) {
    return (
      <Router>
        <Route exact path="">
          <div className="App">
            <QuestionCategoryShort
              currentCategory={currentCategory}
              currentIndex={currentIndex}
              setUserData={setUserData}
              userData={userData}
              qData={qData}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              category={
                currentIndex + 1 < qData.length
                  ? qData[currentIndex + 1].category
                  : ""
              }
              is_7ctos={is_7ctos}
              setSubscribed={setSubscribed}
              subscribed={subscribed}
              setComplete={setComplete}
              setCompleteDoc={setCompleteDoc}
              completeDoc={completeDoc}
              extendedVersion={extendedVersion}
              GAemail={GAemail}
              GASubscribed={GASubscribed}
              GAcomplete={GAcomplete}
            />
          </div>
        </Route>
      </Router>
    );
  }

  //long version below
  else if (isLoaded && !complete && extendedVersion) {
    return (
      <Router>
        <Route exact path="">
          <div className="App">
            <QuestionCategory
              currentCategory={currentCategory}
              currentIndex={currentIndex}
              setUserData={setUserData}
              userData={userData}
              qData={qData}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              category={
                currentIndex + 1 < qData.length
                  ? qData[currentIndex + 1].category
                  : ""
              }
              is_7ctos={is_7ctos}
              setSubscribed={setSubscribed}
              subscribed={subscribed}
              setCompleteDoc={setCompleteDoc}
              completeDoc={completeDoc}
              setComplete={setComplete}
              DBloaded={DBloaded}
              GAemail={GAemail}
              GASubscribed={GASubscribed}
              GAcomplete={GAcomplete}
              extendedVersion={extendedVersion}
            />
            <Preamble is_7ctos={is_7ctos} extendedVersion={extendedVersion} />
            <Particles className="background" params={particleEffects} />
            <div className="CTO-self-assessment">
              {qData.map((question, index) => (
                <VisibilitySensor
                  key={"vis" + index}
                  onChange={isVis => {
                    if (isVis) {
                      setCurrentCategory(qData[index].category);
                    }
                  }}
                >
                  <Element className={"element" + index} key={index}>
                    {qData[index].visible &&
                    (index < 31 || qData[30].answer.normal === "Yes") ? (
                      <div className={"question-box"} key={index}>
                        <div
                          className={"Card-Disappear index" + index}
                          key={index}
                        >
                          <Question
                            questionIndex={index}
                            isLoaded={isLoaded}
                            state={question}
                            setUserAnswer={setUserAnswer}
                            setUnsureUserAnswer={setUnsureUserAnswer}
                            setDkCheck={setDkCheck}
                            clickAnything={clickAnything}
                          />
                        </div>
                      </div>
                    ) : (
                      <div />
                    )}
                  </Element>
                </VisibilitySensor>
              ))}
            </div>

            {window.innerWidth < 1000 && window.innerWidth > 800 && is_7ctos && (
              <img
                className="Collab"
                src={Collab}
                alt="Graphic"
                style={{
                  width: "25vw",
                  position: "sticky",
                  left: "70vw",
                  bottom: "2vh"
                }}
              />
            )}

            {window.innerWidth < 800 && is_7ctos && (
              <img
                className="Collab"
                src={Collab}
                alt="Graphic"
                style={{
                  width: "33vw",
                  position: "sticky",
                  left: "63vw",
                  bottom: "2vh"
                }}
              />
            )}

            {window.innerWidth < 1000 && !is_7ctos && (
              <img
                className="Collab"
                src={Solo}
                alt="Graphic"
                style={{
                  width: "22vw",
                  position: "sticky",
                  left: "72vw",
                  bottom: "2vh"
                }}
              />
            )}
          </div>
        </Route>
      </Router>
    );
  } else if (isLoaded && complete && extendedVersion) {
    return (
      <Router>
        <Route exact path="">
          <div className="App">
            <QuestionCategory
              currentCategory={currentCategory}
              currentIndex={currentIndex}
              setUserData={setUserData}
              userData={userData}
              qData={qData}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
              category={
                currentIndex + 1 < qData.length
                  ? qData[currentIndex + 1].category
                  : ""
              }
              is_7ctos={is_7ctos}
              setSubscribed={setSubscribed}
              subscribed={subscribed}
              setComplete={setComplete}
              setCompleteDoc={setCompleteDoc}
              completeDoc={completeDoc}
              extendedVersion={extendedVersion}
              GAemail={GAemail}
              GASubscribed={GASubscribed}
              GAcomplete={GAcomplete}
            />
          </div>
        </Route>
      </Router>
    );
  }
}

export default App;
