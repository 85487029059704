import React, {useState} from "react";
import RadioButtons from "./radioButtons";
import UnsureToggle from "./unsure";
import UnsureRadioButtons from "./unsureRadioButtons";
import Proptypes from "prop-types";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { withStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
/* import "bootstrap/dist/css/bootstrap.min.css"; */

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))(Tooltip);

export default function Question(props) {

  const [open, setOpen] = useState(false);

  const {
    isLoaded,
    setUserAnswer,
    setUnsureUserAnswer,
    questionIndex,
    setDkCheck
  } = props;
  const { question, info, answers, answer, dk_unit } = props.state;

  if (isLoaded) {
    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
        <div className="Question">
          {question}
          {info === "" ? (
            <div></div>
          ) : (
            <LightTooltip title={info} open={open}>
              <sup>
                <InfoIcon
                  color="secondary"
                  onClick={() => setOpen(true)}
                  onMouseOver={() => setOpen(true)}
                  onMouseOut={() => setOpen(false)}
                />
              </sup>
            </LightTooltip>
          )}
        </div>
        <Container>
          <Row>
            <Col className="Options">
              <RadioButtons
                answers={answers}
                question={question} // CR alebron: do we need to pass this in?
                userAnswer={answer}
                setUserAnswer={setUserAnswer}
                questionIndex={questionIndex}
                clickAnything={props.clickAnything}
              />
            </Col>
            {dk_unit === 0 ? (
              <div></div>
            ) : (
              <Col className="Options-Right">
                <Row>
                  <UnsureToggle
                    dk_check={answer.dk_check}
                    setDkCheck={setDkCheck}
                    questionIndex={questionIndex}
                  />
                </Row>
                {answer.dk_check && (
                  <Row>
                    <UnsureRadioButtons
                      unsureUserAnswer={answer}
                      dk_unit={dk_unit}
                      setUnsureUserAnswer={setUnsureUserAnswer}
                      questionIndex={questionIndex}
                    />
                  </Row>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </React.Fragment>
    );
  } else return <React.Fragment></React.Fragment>;
}

Question.propTypes = {
  isLoaded: Proptypes.bool.isRequired,
  setUserAnswer: Proptypes.func.isRequired,
  setUnsureUserAnswer: Proptypes.func.isRequired,
  questionIndex: Proptypes.number.isRequired,
  setDkCheck: Proptypes.func.isRequired
  //Figure out how to check nested props -->  const { question, answers, answer } = props.state;
};
