import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Proptypes from "prop-types";

export default function RadioButtons(props) {
  const { answers, userAnswer, setUserAnswer, questionIndex } = props;

  const handleChange = event => {
    setUserAnswer(event.target.value, questionIndex);
    props.clickAnything();
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="position"
        name="position"
        value={userAnswer.normal} //The slection (below) gets passed up into app state which gets passed down back into value --> Better method?
        onChange={handleChange}
      >
        {answers.map(answer => (
          <FormControlLabel
            disabled={userAnswer.dk_check ? true : false}
            className="Options-col"
            value={userAnswer.dk_check ? `undefined` : answer.ans}
            control={<Radio color="secondary" />}
            label={answer.ans}
            key={answer.ans}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtons.propTypes = {
  answers: Proptypes.array.isRequired,
  userAnswer: Proptypes.object.isRequired,
  setUserAnswer: Proptypes.func.isRequired,
  questionIndex: Proptypes.number.isRequired
};