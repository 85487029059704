import React from "react";
import Subscribe from "./subscribe";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Proptypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

var data = require("../data/Results.json");

const theme = createMuiTheme({
  palette: {
    type: "light"
  }
});

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  }
});

export default function SimpleTable(props) {
  const classes = useStyles();
  const {
    scores,
    preAnswers,
    isSubmitted,
    is_7ctos,
    setSubscribed,
    subscribed,
    sendEmailClicked,
    extendedVersion
  } = props;

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  if (!isSubmitted && subscribed) scrollToTop();

  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="results-wrapper">
      <MuiThemeProvider theme={theme}>
        <h1
          style={{
            marginTop: "3vh",
            marginBottom: "5vh",
            textAlign: "center"
          }}
        >
          Self-Assessment Results
        </h1>

        <Paper className={classes.root}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "600" }}>Categories</TableCell>
                <TableCell style={{ fontWeight: "600" }} align="center">
                  Your Initial Rating
                </TableCell>
                <TableCell style={{ fontWeight: "600" }} align="center">
                  Your Actual Results
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(scores).map((category, index) => (
                <React.Fragment key={index + "React.Fragment"}>
                  <TableRow key={category}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ backgroundColor: "#F8F8F8" }}
                    >
                      <div style={{ fontWeight: "bold" }}>{category}</div>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        backgroundColor: "#F8F8F8"
                      }}
                      align="center"
                    >
                      {preAnswers[index] === "Below Industry Standard" && (
                        <Chip
                          label="Below Industry Standard"
                          style={{
                            background: "red",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                      {preAnswers[index] === "Meets Industry Standard" && (
                        <Chip
                          label="Meets Industry Standard"
                          style={{
                            background: "green",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                      {preAnswers[index] === "Exceeds Industry Standard" && (
                        <Chip
                          label="Exceeds Industry Standard"
                          style={{
                            background: "#01579b",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "center",
                        backgroundColor: "#F8F8F8"
                      }}
                      align="center"
                    >
                      {scores[category] < -3 && (
                        <Chip
                          label="Below Industry Standard"
                          style={{
                            background: "red",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                      {scores[category] >= -3 && scores[category] <= 4 && (
                        <Chip
                          label="Meets Industry Standard"
                          style={{
                            background: "green",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                      {scores[category] > 4 && (
                        <Chip
                          label="Exceeds Industry Standard"
                          style={{
                            background: "#01579b",
                            opacity: "0.7",
                            color: "white"
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={3}>
                      {scores[category] < -3 && (
                        <div style={{ color: "black" }}>
                          {data[category].below.link !== "" && (
                            <React.Fragment>
                              <div style={{ textAlign: "center" }}>
                                <iframe
                                  title={data[category].meets.link}
                                  width="420"
                                  height="235"
                                  src={data[category].below.link}
                                ></iframe>
                              </div>
                              <br></br>
                            </React.Fragment>
                          )}
                          {data[category].below.text + " "}
                        </div>
                      )}
                      {scores[category] >= -3 && scores[category] <= 4 && (
                        <div style={{ color: "black" }}>
                          {data[category].meets.link !== "" && (
                            <React.Fragment>
                              <div style={{ textAlign: "center" }}>
                                <iframe
                                  title={data[category].meets.link}
                                  width="420"
                                  height="235"
                                  src={data[category].meets.link}
                                ></iframe>
                              </div>
                              <br></br>
                            </React.Fragment>
                          )}
                          {data[category].meets.text + " "}
                        </div>
                      )}
                      {scores[category] > 4 && (
                        <div style={{ color: "black" }}>
                          {data[category].above.link !== "" && (
                            <React.Fragment>
                              <div style={{ textAlign: "center" }}>
                                <iframe
                                  title={data[category].meets.link}
                                  width="420"
                                  height="235"
                                  src={data[category].above.link}
                                ></iframe>
                              </div>
                              <br></br>
                            </React.Fragment>
                          )}
                          {data[category].above.text + " "}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </Paper>

        {!isSubmitted && is_7ctos && (
          <div
            style={{
              marginTop: "3vh",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <Subscribe setSubscribed={setSubscribed} />
          </div>
        )}

        {isSubmitted && subscribed && (
          <p
            style={{
              marginTop: "3vh",
              textAlign: "center",
              fontSize: "1em",
              color: "grey"
            }}
          >
            Essilen Research will examine your results individually, and will be
            in contact to provide more context and concrete next steps for
            improvment. Thank you.
          </p>
        )}

        <div className="sendEmail">
          {!extendedVersion && !isSubmitted && (
            <React.Fragment>
              <Button
                onClick={() => {
                  sendEmailClicked();
                }}
                size="large"
                variant="outlined"
                color="primary"
              >
                Email My Full Report
              </Button>
              <p
                style={{
                  marginTop: "3vh",
                  marginBottom: "2vh",
                  textAlign: "center",
                  fontSize: "1em",
                  color: "grey"
                }}
              >
                Submit your email to get your full personalized recommendations
                and how your score was calculated. 
              </p>
            </React.Fragment>
          )}
        </div>
      </MuiThemeProvider>
      {isSubmitted &&
        subscribed &&
        window.location.search.substring(1).length < 25 && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={open}
            autoHideDuration={10000}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">Email Successfully Sent!</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        )}
    </div>
  );
}

SimpleTable.propTypes = {
  scores: Proptypes.object.isRequired,
  preAnswers: Proptypes.array.isRequired,
  isSubmitted: Proptypes.bool.isRequired,
  is_7ctos: Proptypes.bool.isRequired,
  setSubscribed: Proptypes.func.isRequired,
  subscribed: Proptypes.bool.isRequired,
  sendEmailClicked: Proptypes.func.isRequired
};
