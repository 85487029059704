import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBKI1Y7VJxoCVC8gMF1h_fCiyOBTcsfelE",
  authDomain: "self-assessment-111bb.firebaseapp.com",
  databaseURL: "https://self-assessment-111bb.firebaseio.com",
  projectId: "self-assessment-111bb",
  storageBucket: "self-assessment-111bb.appspot.com",
  messagingSenderId: "474116910184",
  appId: "1:474116910184:web:cd5ac269f7c233451f1fdd",
  measurementId: "G-BDDJ00X5BD"
});

const db = firebaseApp.firestore();

export { db };