function sendEmail(
  values,
  userEmailInfo,
  is_7ctos,
  qData,
  returnURL,
  extendedVersion,
  subscribed
) {
  function sendInternal() {
    if (is_7ctos) {
      var cc = "alebron@gmail.com";
    }

    var state = {
      user: values.name,
      /* user_email: values.email, */
      cc: cc,
      company: values.company,
      time: new Date()
    };

    userEmailInfo.forEach((category, index) => {
      var sect = "section" + index;
      state[sect] = {
        stated:
          category.stated === "Below Industry Standard"
            ? '<font color="red">' + category.stated + "</font>"
            : category.stated === "Meets Industry Standard"
            ? '<font color="green">' + category.stated + "</font>"
            : '<font color="#039be5">' + category.stated + "</font>",
        actual:
          category.actual === "Below Industry Standard"
            ? '<font color="red">' + category.actual + "</font>"
            : category.actual === "Meets Industry Standard"
            ? '<font color="green">' + category.actual + "</font>"
            : '<font color="#039be5">' + category.actual + "</font>",
        discription: category.explanation,
        link: category.link
      };
    });

    //qData formatting
    var formattedData = qData.map((q, index) => ({
      //To it to dynamically gen q, we > 50kb req limit
      /*       ["question" + index]: q.answer.normal
        ? q.question
        : q.answer.dontknow
        ? q.question
        : q.answer.dk_check
        ? q.question
        : "", */
      ["answer" + index]: q.answer.dk_check
        ? "DK: " + q.answer.dontknow
        : q.answer.normal
    }));

    state.section4title = state.section4 === undefined ? "" : "Data Science";

    state.section4discription =
      state.section4 === undefined ? "" : state.section4.discription;

    state.section4link =
      state.section4 === undefined ? "" : state.section4.link;

    var sectionOne = {
      user: state.user.charAt(0).toUpperCase() + state.user.slice(1),
      user_email: state.user_email,
      cc: state.cc,
      company: state.company,
      time: state.time,
      section0actual: state.section0.actual,
      section0stated: state.section0.stated,
      section0discription: state.section0.discription,
      section0link: state.section0.link,
      section1actual: state.section1.actual,
      section1stated: state.section1.stated,
      section1discription: state.section1.discription,
      section1link: state.section1.link,
      section2actual: state.section2.actual,
      section2stated: state.section2.stated,
      section2discription: state.section2.discription,
      section2link: state.section2.link,
      section3actual: state.section3.actual,
      section3stated: state.section3.stated,
      section3discription: state.section3.discription,
      section3link: state.section3.link,
      section4title: state.section4title,
      section4actualpre:
        state.section4 === undefined ? "" : "Your Actual Results: ",
      section4actual:
        state.section4 === undefined
          ? ""
          : state.section4.actual === "Below Industry Standard"
          ? '<font color="red">' + state.section4.actual + "</font>"
          : state.section4.actual === "Meets Industry Standard"
          ? '<font color="green">' + state.section4.actual + "</font>"
          : '<font color="#039be5">' + state.section4.actual + "</font>",
      section4statedpre:
        state.section4 === undefined ? "" : "Your Initial Rating: ",
      section4stated:
        state.section4 === undefined
          ? ""
          : state.section4.stated === "Below Industry Standard"
          ? '<font color="red">' + state.section4.stated + "</font>"
          : state.section4.actual === "Meets Industry Standard"
          ? '<font color="green">' + state.section4.stated + "</font>"
          : '<font color="#039be5">' + state.section4.stated + "</font>",
      section4discription: state.section4discription,
      section4link: state.section4link,
      returnURL: "<a href='" + returnURL + "'>Click Here!</a>",
      HotLead: subscribed ? "HOT LEAD! " : ""
    };

    var sectionTwo = Object.assign({}, ...formattedData);
    const templateInfo = {
      ...sectionOne,
      ...sectionTwo
    };

    function handleSubmit() {
      const templateId = extendedVersion
        ? "essilen_internal"
        : "essilen_internal_clone";
      sendFeedback(templateId, templateInfo);
    }

    function sendFeedback(templateId, variables) {
      window.emailjs
        .send("gmail", templateId, variables)
        .then(res => {
          console.log("Email successfully sent!");
        })
        .catch(err =>
          console.error(
            "Oh well, you failed. Here some thoughts on the error that occured:",
            err
          )
        );
    }
    handleSubmit();
  }

  function sendExternal() {
    var state = {
      user: values.name,
      user_email: values.email
    };

    userEmailInfo.forEach((category, index) => {
      var sect = "section" + index;
      state[sect] = {
        stated:
          category.stated === "Below Industry Standard"
            ? '<font color="red">' + category.stated + "</font>"
            : category.stated === "Meets Industry Standard"
            ? '<font color="green">' + category.stated + "</font>"
            : '<font color="#039be5">' + category.stated + "</font>",
        actual:
          category.actual === "Below Industry Standard"
            ? '<font color="red">' + category.actual + "</font>"
            : category.actual === "Meets Industry Standard"
            ? '<font color="green">' + category.actual + "</font>"
            : '<font color="#039be5">' + category.actual + "</font>",
        discription: category.explanation,
        link: category.link
      };
    });

    state.section4title = state.section4 === undefined ? "" : "Data Science";

    state.section4discription =
      state.section4 === undefined ? "" : state.section4.discription;

    state.section4link =
      state.section4 === undefined ? "" : state.section4.link;

    function handleSubmit() {
      const templateId = "essilen_external";
      sendFeedback(templateId, {
        user: state.user.charAt(0).toUpperCase() + state.user.slice(1),
        user_email: state.user_email,
        section0actual: state.section0.actual,
        section0stated: state.section0.stated,
        section0discription: state.section0.discription,
        section0link: state.section0.link,
        section1actual: state.section1.actual,
        section1stated: state.section1.stated,
        section1discription: state.section1.discription,
        section1link: state.section1.link,
        section2actual: state.section2.actual,
        section2stated: state.section2.stated,
        section2discription: state.section2.discription,
        section2link: state.section2.link,
        section3actual: state.section3.actual,
        section3stated: state.section3.stated,
        section3discription: state.section3.discription,
        section3link: state.section3.link,
        section4title: state.section4title,
        section4actualpre:
          state.section4 === undefined ? "" : "Your Actual Results: ",
        section4actual:
          state.section4 === undefined
            ? ""
            : state.section4.actual === "Below Industry Standard"
            ? '<font color="red">' + state.section4.actual + "</font>"
            : state.section4.actual === "Meets Industry Standard"
            ? '<font color="green">' + state.section4.actual + "</font>"
            : '<font color="#039be5">' + state.section4.actual + "</font>",
        section4statedpre:
          state.section4 === undefined ? "" : "Your Initial Rating: ",
        section4stated:
          state.section4 === undefined
            ? ""
            : state.section4.stated === "Below Industry Standard"
            ? '<font color="red">' + state.section4.stated + "</font>"
            : state.section4.actual === "Meets Industry Standard"
            ? '<font color="green">' + state.section4.stated + "</font>"
            : '<font color="#039be5">' + state.section4.stated + "</font>",
        section4discription: state.section4discription,
        section4link: state.section4link,
        returnURL: "<a href='" + returnURL + "'>Click Here!</a>"
      });
    }

    function sendFeedback(templateId, variables) {
      window.emailjs
        .send("gmail", templateId, variables)
        .then(res => {
          console.log("Email successfully sent!");
        })
        .catch(err =>
          console.error(
            "Oh well, you failed. Here some thoughts on the error that occured:",
            err
          )
        );
    }
    handleSubmit();
  }
  sendInternal();
  /* sendExternal(); */
}

export default sendEmail;
