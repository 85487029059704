import React, { useState } from "react";
import Submission from "./submit";
import Results from "./results";
import Logo from "../images/Logo.png";
import LinearDeterminateShort from "./Short_progress";
import Proptypes from "prop-types";
import sendToServerPre from "../functions/serverPre";

export default function QuestionCategoryShort(props) {
  const [sendEmailClicked, setSendEmailClicked] = useState(false);
  const [sent, setSent] = useState(false);

  const {
    setUserData,
    userData, //is it nessary? Check w/ prop type later
    qData,
    isSubmitted,
    setIsSubmitted,
    currentCategory,
    currentIndex,
    subscribed,
    is_7ctos,
    setSubscribed,
    setComplete,
    setCompleteDoc,
    completeDoc,
    DBloaded,
    extendedVersion,
    GAemail,
    GAcomplete,
    GASubscribed
  } = props;

  function extractScores(excludeDataScience) {
    function reducer(prev, qd) {
      if (qd.category in prev) {
        prev[qd.category] += qd.score;
      } else {
        prev[qd.category] = qd.score;
      }
      return prev;
    }
    var res = qData.reduce(reducer, {});
    delete res["general"];
    if (excludeDataScience) {
      delete res["Data Science"];
    }
    return res;
  }

  function preQuestions() {
    var preAnswers = [];
    qData.forEach(data => {
      if (data.is_pre) {
        preAnswers.push(data.answer.normal);
      }
    });
    return preAnswers;
  }

  var userEmailInfo = [];
  var data = require("../data/Results.json");

  function generateUserEmailInfo() {
    const excludeDataScience = qData[20].answer.normal !== "Yes";
    var scores = extractScores(excludeDataScience);
    Object.keys(scores).forEach((category, index) => {
      userEmailInfo.push({
        actual:
          scores[category] < -3
            ? "Below Industry Standard"
            : scores[category] > 4
            ? "Exceeds Industry Standard"
            : "Meets Industry Standard",
        stated: preQuestions()[index],
        explanation:
          scores[category] < -3
            ? data[category].below.text
            : scores[category] > 4
            ? data[category].above.text
            : data[category].meets.text,
        link:
          scores[category] < -3
            ? data[category].below.link === ""
              ? ""
              : "<a href=" +
                subEmbedToWatch(data[category].below.link) +
                ">See How</a>"
            : scores[category] > 4
            ? data[category].above.link === ""
              ? ""
              : "<a href=" +
                subEmbedToWatch(data[category].above.link) +
                ">See How</a>"
            : data[category].meets.link === ""
            ? ""
            : "<a href=" +
              subEmbedToWatch(data[category].meets.link) +
              ">See How</a>"
      });
    });
  }

  function subEmbedToWatch(url) {
    var link = url;
    var newURL = link.replace(/embed/g, "watch");
    return newURL;
  }

  generateUserEmailInfo();

  function renderCategoryName() {
    return (
      <div className="white-box-wrapper">
        <div className="text">
          <LinearDeterminateShort currentIndex={currentIndex} qData={qData} />
          <div
            style={{
              textTransform: "uppercase",
              fontSize: "3vh",
              margin: "2.5vh",
              fontFamily: "BrandonGrotesque"
            }}
          >
            {"  " + currentCategory}
            {window.innerWidth > 1400 && (
              <img className="Logo" src={Logo} alt="Graphic" />
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderSubmitPage() {
    return (
      <div className="white-box-wrapper-submit">
        <Submission
          setUserData={setUserData}
          userData={userData} //is it nessary? Check w/ prop type later
          qData={qData}
          setIsSubmitted={setIsSubmitted}
          preQuestions={preQuestions}
          userEmailInfo={userEmailInfo}
          subscribed={subscribed}
          is_7ctos={is_7ctos}
          setSubscribed={setSubscribed}
          completeDoc={completeDoc}
          GAemail={GAemail}
          GASubscribed={GASubscribed}
          extendedVersion={extendedVersion}
        />
      </div>
    );
  }

  function sendEmailClickedSet() {
    setSendEmailClicked(true);
  }

  function renderResults() {
    const excludeDataScience = qData[20].answer.normal !== "Yes";
    return (
      <div className="white-box-wrapper-results">
        <Results
          scores={extractScores(excludeDataScience)}
          preAnswers={preQuestions()}
          is_7ctos={is_7ctos}
          isSubmitted={isSubmitted}
          sendEmailClicked={sendEmailClickedSet}
          setSubscribed={setSubscribed}
          subscribed={subscribed}
          DBloaded={DBloaded}
          extendedVersion={extendedVersion}
        />
      </div>
    );
  }

  var query = window.location.search.substring(1);
  var vars = query.split("|");
  var URL = vars[vars.length - 1];

  if (isSubmitted || URL.length > 20) {
    setComplete(true);
    return renderResults();
  } else if (
    currentIndex < 21 ||
    (qData[21].answer.normal === "Yes" && currentIndex + 1 < qData.length)
  ) {
    return renderCategoryName();
  } else if ((!extendedVersion && !sendEmailClicked) || isSubmitted) {
    setComplete(true);
    if (!sent) {
      setSent(true);
      sendToServerPre(qData, setCompleteDoc);
      GAcomplete();
    }
    return renderResults();
  } else if ((!isSubmitted && extendedVersion) || sendEmailClicked) {
    setComplete(true);
    if (!sent) {
      setSent(true);
      sendToServerPre(qData, setCompleteDoc); 
    }
    return renderSubmitPage();
  } else if (isSubmitted) {
    setComplete(true);
    return renderResults();
  }
}

QuestionCategoryShort.propTypes = {
  setUserData: Proptypes.func.isRequired,
  qData: Proptypes.array.isRequired,
  isSubmitted: Proptypes.bool.isRequired,
  setIsSubmitted: Proptypes.func.isRequired,
  currentCategory: Proptypes.string.isRequired,
  currentIndex: Proptypes.number.isRequired,
  subscribed: Proptypes.bool.isRequired,
  is_7ctos: Proptypes.bool.isRequired,
  setSubscribed: Proptypes.func.isRequired,
  setComplete: Proptypes.func.isRequired,
  setCompleteDoc: Proptypes.func.isRequired
};
