import React from "react";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Proptypes from "prop-types";

export const dk_unit_1 = {
  'Minutes': 2,
  'Hours': 0,
  'Days': -1,
  'Weeks': -3,
  'Months': -5
};

export const dk_unit_2 = {
  'Minutes': 1,
  'Hours': -1,
  'Days': -3
};

export const dk_unit_3 = {
  'Uh-oh': -5
};

export default function UnsureRadioButtons(props) {
  const { unsureUserAnswer, setUnsureUserAnswer, questionIndex, dk_unit } = props;

  const DK1 = Object.keys(dk_unit_1)
  const DK2 = Object.keys(dk_unit_2)
  const DK3 = Object.keys(dk_unit_3)

  const handleChange = event => {
    setUnsureUserAnswer(event.target.value, questionIndex);
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="position"
        name="position"
        value={unsureUserAnswer.dontknow}
        onChange={handleChange}
      >
        { (dk_unit === 1 ? DK1 : (dk_unit === 2 ? DK2: DK3 )).map(answer => (
          <FormControlLabel
            className="Options-col"
            value={unsureUserAnswer.dk_check ? answer : unsureUserAnswer.normal}
            control={<Radio color="secondary" />}
            label={answer}
            key={answer}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

UnsureRadioButtons.propTypes = {
  unsureUserAnswer: Proptypes.object.isRequired,
  setUnsureUserAnswer: Proptypes.func.isRequired,
  questionIndex: Proptypes.number.isRequired
};