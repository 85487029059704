import React, { useState } from "react";
import Subscribe from "./subscribe";
import sendToServer from "../functions/server.js";
import sendEmail from "../functions/sendEmail";
import getQueryDict from "../functions/queryString";
import EmailSVG from "../images/EmailSVG.svg";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import validator from "email-validator";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Proptypes from "prop-types";

const theme = createMuiTheme({
  palette: {
    type: "light"
  }
});

export default function CustomizedInputs(props) {
  const {
    qData,
    setIsSubmitted,
    is_7ctos,
    setSubscribed,
    subscribed,
    preQuestions,
    userEmailInfo,
    completeDoc,
    extendedVersion
  } = props;
  const [values, setValues] = useState({});
  const queryDict = getQueryDict();
  var w = window.innerWidth;

  const handleChangeCompany = company => event => {
    setValues({ ...values, [company]: event.target.value });
  };
  const handleChangeName = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleChangeEmail = email => event => {
    setValues({ ...values, [email]: event.target.value });
  };

  if (w < 1000) {
    w = w * 2; // Normalizes text feild size for mobile
  }

  //Snackbar info
  const [open, setOpen] = React.useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function handleGA() {
    if (subscribed) {
      props.GASubscribed();
      props.GAemail();
    } else {
      props.GAemail();
    }
  }

  return (
    <div className="Results">
      <img className="EmailSVG" src={EmailSVG} alt="Email"></img>
      <MuiThemeProvider theme={theme}>
        <div className="submit-white-box">
          <form className="" noValidate>
            <br></br>
            <br></br>
            <TextField
              required
              id="outlined-required"
              label="Company Name"
              margin="normal"
              variant="outlined"
              onChange={handleChangeCompany("company")}
              style={{ width: w * (1 / 3) }}
            />
            <br />
            <TextField
              required
              id="outlined-required"
              label="Your Name"
              margin="normal"
              variant="outlined"
              onChange={handleChangeName("name")}
              style={{ width: w * (1 / 3) }}
            />
            <br />
            <TextField
              required
              id="outlined-required"
              label="Email"
              margin="normal"
              variant="outlined"
              onChange={handleChangeEmail("email")}
              style={{ width: w * (1 / 3) }}
            />
          </form>
          <br></br>
          {values.company !== undefined &&
          values.name !== undefined &&
          validator.validate(values.email) ? (
            <React.Fragment>
              {!is_7ctos && <Subscribe setSubscribed={setSubscribed} />}
              <br></br>
              <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={() => {
                  sendToServer(
                    qData,
                    values,
                    queryDict.client,
                    queryDict.id,
                    subscribed,
                    completeDoc
                  );
                  sendEmail(
                    values,
                    userEmailInfo,
                    is_7ctos,
                    qData,
                    window.location.href + "?" + completeDoc,
                    extendedVersion,
                    subscribed
                  );
                  setIsSubmitted(true);
                  preQuestions();
                  handleGA();
                }}
              >
                SEE MORE DETAILS
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!is_7ctos && <Subscribe setSubscribed={setSubscribed} />}
              <br></br>
              <Button disabled size="large" variant="outlined" color="primary">
                SEE MORE DETAILS
              </Button>
            </React.Fragment>
          )}
        </div>
      </MuiThemeProvider>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">Last Step!</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

CustomizedInputs.propTypes = {
  qData: Proptypes.array.isRequired,
  setIsSubmitted: Proptypes.func.isRequired,
  is_7ctos: Proptypes.bool.isRequired,
  subscribed: Proptypes.bool.isRequired,
  setSubscribed: Proptypes.func.isRequired,
  preQuestions: Proptypes.func.isRequired,
  userEmailInfo: Proptypes.array.isRequired
};
