import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Proptypes from "prop-types";

export default function UnsureToggle(props) {
  const { setDkCheck, questionIndex, dk_check } = props;

  const handleChange = () => {
    setDkCheck(questionIndex);
  };

  return (
    <React.Fragment>
      <FormControlLabel
        className="Options-col"
        control={
          <Switch
            checked={dk_check}
            onChange={handleChange}
            value="checked"
          />
        }
        label={
          dk_check
            ? "How long will it take to find out?"
            : "Not Sure"
        }
      />
    </React.Fragment>
  );
}

UnsureToggle.propTypes = {
  setDkCheck: Proptypes.func.isRequired,
  questionIndex: Proptypes.number.isRequired,
  dk_check: Proptypes.bool.isRequired
};