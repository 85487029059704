import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Proptypes from "prop-types";

export default function Subscribe(props) {
  //checkbox
  const {setSubscribed} = props;
  const [checked, setChecked] = useState(true);

  function handleChange() {
    setChecked(!checked);
    setSubscribed(!checked);
  }
  //checkbox

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={() => handleChange()}
              value="checkedB"
              color="primary"
            />
          }
          label="I want to schedule a call with you to go over my results"
          style={{
            fontSize: "0.5em",
            color: "grey"
          }}
        />
      </FormGroup>
    </div>
  );
}

Subscribe.propTypes = {
  setSubscribed: Proptypes.func.isRequired
};
