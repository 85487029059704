import { db } from "../firebase";
import uuidv4 from "uuid/v4";

function sendToServerPre(qData, setCompleteDoc) {
  const formattedData = qData.map(q => ({
    question: q.question,
    answer: q.answer.dk_check ? "DK: " + q.answer.dontknow : q.answer.normal
  }));

  var results = {};
  results.url = window.location.href;
  results.timestamp = new Date();
  results.data = formattedData;
  results.subscribed = false;

  var documentID = uuidv4();

  db.collection("Data")
    .doc(documentID)
    .set({
      results
    })
    .then(function() {
      setCompleteDoc(documentID);
      console.log("Document successfully written!");
    })
    .catch(function(error) {
      console.error("Error writing document: ", error);
    });
}

export default sendToServerPre;
