import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Proptypes from "prop-types";

export default function LinearDeterminate(props) {
  const {qData, currentIndex} = props;
  var progress = 0;

  if (qData[30].answer.normal === "Yes") {
    progress = ((currentIndex + 1) / qData.length) * 100
  } else {
    progress = ((currentIndex + 1) / 31) * 100
      if (qData[1].visible === false) {
        progress = 0;
      }
  }
    return (
      <div>
        <LinearProgress
          variant="determinate"
          value={progress}
          color="secondary"
        />
      </div>
    );
}

LinearDeterminate.propTypes = {
  qData: Proptypes.array.isRequired,
  currentIndex: Proptypes.number.isRequired
};

